import { innovorderApi } from '../index';
import { ApiResponse, ApiRequest } from '../request.types';
import {
    AddCrousModulePayload,
    AddCrousModuleResponse,
    GetCrousModulePayload,
    GetCrousModuleResponse,
    PutCrousModulePayload,
    PutCrousModuleResponse,
} from './moduleCrous.type';

const moduleCrousApi = innovorderApi.injectEndpoints({
    endpoints: (build) => ({
        getCrousModule: build.query<GetCrousModuleResponse, ApiRequest<GetCrousModulePayload>>({
            query: ({ restaurantId, token }) => ({
                url: `/module_crous/restaurants/${restaurantId}`,
                method: 'GET',
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<GetCrousModuleResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        addCrousModule: build.mutation<AddCrousModuleResponse, ApiRequest<AddCrousModulePayload>>({
            query: ({ restaurantId, module, token }) => ({
                url: `/module_crous/restaurants/${restaurantId}`,
                method: 'POST',
                body: module,
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<AddCrousModuleResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
        putCrousModule: build.mutation<PutCrousModuleResponse, ApiRequest<PutCrousModulePayload>>({
            query: ({ restaurantId, module, token }) => ({
                url: `/module_crous/restaurants/${restaurantId}`,
                method: 'PUT',
                body: module,
                headers: {
                    authorization: `Bearer ${token}`,
                },
            }),
            transformResponse: (response: ApiResponse<PutCrousModuleResponse>) => response.data,
            extraOptions: { maxRetries: 3 },
        }),
    }),
});

export const { useGetCrousModuleQuery, useAddCrousModuleMutation, usePutCrousModuleMutation } = moduleCrousApi;
